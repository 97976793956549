import app from 'firebase/app';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyD31_v5JI3rLffoCsZ2JpAPfFaBusi8RoY",
    authDomain: "crazybrow420-c75be.firebaseapp.com",
    databaseURL: "https://crazybrow420-c75be.firebaseio.com",
    projectId: "crazybrow420-c75be",
    storageBucket: "crazybrow420-c75be.appspot.com",
    messagingSenderId: "339043280928",
    appId: "1:339043280928:web:929ea3477019a7b6"
};

export enum FirebaseAuthErrors {
    wrongPassword = 'auth/wrong-password',
    userNotFound = 'auth/user-not-found'
}

export class Firebase {
    public auth: app.auth.Auth;
    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
    }

    public currentUser = () => this.auth.currentUser;

    public createUserViaEmailAndPassword = (email: string, password: string, name: string) => {
        return this.auth.createUserWithEmailAndPassword(email, password).then(credentials => credentials.user.updateProfile({
            displayName: name
        }));
    }

    public signInViaEmailAndPassword = (email: string, password: string) => {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    public resetUserPassword = (email: string) => this.auth.sendPasswordResetEmail(email);
    
    public loadProducts = () => null;

    public changeUserPassword = (password: string) => this.auth.currentUser.updatePassword(password);

    public signOut = () => this.auth.signOut();
}

export const instance = new Firebase();