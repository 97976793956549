import * as React from 'react';
import { Map, GoogleApiWrapper, GoogleAPI, MapProps, Marker } from 'google-maps-react';
import './index.css'
import rocket from '../../img/cb_rocket_map.png';

const GoogleMaps = (props: MapProps) => {
    return (
        <div className="map">
            <Map
                google={props.google}
                zoom={14}
                initialCenter={{
                    lat: -34.8932556,
                    lng: -56.1672887
                }}

                disableDefaultUI={true}
                styles={styles}

            >
                <Marker position={{ lat: -34.9067422, lng: -56.1968523 }} icon={rocket} />
                <Marker position={{ lat: -34.8789304, lng: -56.1482962 }} icon={rocket} />
            </Map>
        </div>
    );
}

const styles: Array<google.maps.MapTypeStyle> = [
    { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
    {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }]
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#263c3f' }]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#6b9a76' }]
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#38414e' }]
    },
    {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#212a37' }]
    },
    {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9ca5b3' }]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#746855' }]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1f2835' }]
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#f3d19c' }]
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#2f3948' }]
    },
    {
        featureType: 'transit.station',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }]
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#17263c' }]
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#515c6d' }]
    },
    {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#17263c' }]
    }
];


export default GoogleApiWrapper({
    apiKey: 'AIzaSyBwMbebP_Qbcena8YkkK_Vsv43JNgsOUsY'
})(GoogleMaps);