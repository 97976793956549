import * as React from 'react';
import LoginForm from '../../presentational/LoginForm';
import CrazyBrowContext from '../../Context/globalContext';
import { Redirect } from 'react-router';
import RegisterForm from '../../presentational/RegisterForm';

const RegisterPage = () => {
    const context = React.useContext(CrazyBrowContext);
    return context.user ? <Redirect to="/" /> : <RegisterForm />
};

export default RegisterPage;