import * as React from 'react';

const FirstSection: React.FunctionComponent = () => {
    return (
        <div className="cb_contentSection cb_firstContent">
            <h2 className="efectos">Efectos</h2>
            <p><mark>Luego de  20 a 40 minutos de consumido, comenzaremos a sentir que el cohete despega 🚀, los efectos de percepción de las cosas empieza a variar, las distancias, los colores, hasta los olores se perciben de otra manera, así como el disfrute del camino haciendo que el tiempo pase mucho más lento 🕙. <br />
                Los efectos se podrían comparar con el consumo fumando, pero aún más intenso, ya que el cuerpo asimila mucha más cantidad de thc y cbd 👅🧠.</mark>
            </p>
        </div>
    );
}

export default FirstSection;    