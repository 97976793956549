import * as React from 'react';
import { IProduct } from '../../models/ICrazyBrowApi';
import ProductBlock from './ProductBlock';

interface IProps {
    products: Array<IProduct>
    openHandler: (product: IProduct) => () => void;
}

const ProductList: React.FunctionComponent<IProps> = props => {
    /* console.log(props.products); */
    return (
        <div className="cb_productBlockContainer">
            {props.products.map(p => <ProductBlock product={p} key={p.id} openHandler={props.openHandler} />)}
        </div>
    );
}

export default ProductList;