import * as React from 'react';
import RegisterForm from '../../presentational/RegisterForm';
import LoginForm from '../../presentational/LoginForm';
import CrazyBrowContext from '../../Context/globalContext';
import { Redirect } from 'react-router';
import defaultUserImage from '../../img/defaultUserImage.jpg';

const UserMenu = () => {
    const context = React.useContext(CrazyBrowContext);
    const signOut = () => {
        context.fireBase.signOut()
    }
    if (context.user) {
        return (
            <ul className="cb_userMenu">
                <li onClick={signOut}> Salir</li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        )
    } else {
        return <Redirect to="/login" />
    }
}

export default UserMenu;