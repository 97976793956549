import React, { useContext } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import NavBar from './presentational/NavBar';
/* import ProductWindow from './presentational/ProductWindow'; */
import UserPanel from './Pages/UserPanel';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// imgs
import './img/background-black.jpg';
import Landing from './Pages/Landing';
import AppContext from './Context/AppContext';
import LoginPage from './Pages/Login';
import RegisterPage from './Pages/Register'
import Shop from './Pages/Shop';

const App: React.FunctionComponent = () => {
  return (
    <AppContext>
      <div className="App">
        <Router>
          <NavBar />
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/usuario" exact component={UserPanel} />
            <Route path="/login" exact component={LoginPage} />
            <Route path="/registro" exact component={RegisterPage} />
            <Route path="/tienda" exact component={Shop} />
          </Switch>
        </Router>

      </div>
    </AppContext>
  );
}

export default App;
