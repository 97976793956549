import * as React from 'react';
import astronaut from '../../img/astronautabrownie.png';
import chala from '../../img/cb_chala.png';
import rocket from '../../img/cb_rocket.png';
const DetailsSection: React.FunctionComponent = () => {
    return (
        <div className="cb_contentSection cb_detailsSection">
            <h2 className="manual">Manual</h2>
            <div className="d-none  d-md-flex">
                <ul className="cb_detailsSection-list">
                    <li ><img src={chala} alt="chala" width="50px" height="50px" /><span>Dósis por astronauta: <br /> <span className="cb_detailsSection-highlight">1 brownie</span></span></li>
                    <li><span className="cb_emoji">🕙</span><span>Tiempo de despegue: <br /> <span className="cb_detailsSection-highlight">20 a 50 minutos luego de ser ingerido</span></span></li>
                    <li><img src={rocket} alt="chala" width="50px" height="50px" /><span>Tiempo de viaje: <br /> <span className="cb_detailsSection-highlight">3 a 5 horas.</span></span></li>
                </ul>
                <div className="cb_astronaut"><img src={astronaut} alt="" /></div>
            </div>
            <div className="d-xs-flex d-md-none small">
                <ul className=" cb_detailsSection-list">
                    <li ><img src={chala} alt="chala" width="50px" height="50px" /><span>Dósis por astronauta: <br /> <span className="cb_detailsSection-highlight">1 brownie</span></span></li>
                    <li><span className="cb_emoji">🕙</span><span>Tiempo de despegue: <br /> <span className="cb_detailsSection-highlight">20 a 50 minutos luego de ser ingerido</span></span></li>
                    <li><img src={rocket} alt="chala" width="50px" height="50px" /><span>Tiempo de viaje: <br /> <span className="cb_detailsSection-highlight">3 a 5 horas.</span></span></li>
                </ul>
                <div className="cb_astronaut"><img src={astronaut} alt="" /></div>
                {/*  <div className="cb_spaceship"></div> */}
            </div>
        </div>
    );
}

export default DetailsSection;    