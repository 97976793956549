import * as React from 'react';
import { IProduct } from '../../models/ICrazyBrowApi';
import brownie from '../../img/brow1.png'
import { Button } from 'react-bootstrap';
import CrazyBrowContext from '../../Context/globalContext';
interface IProps {
    product: IProduct;
    openHandler: (product: IProduct) => () => void;
}

const ProductBlock: React.FunctionComponent<IProps> = props => {
    const context = React.useContext(CrazyBrowContext);
    const addToCart = () => context.cart.addToCart(props.product, 1);
    return (
        <div className="cb_productBlock">
            <img src={brownie} alt="" />
            <span>{props.product.name}</span>
            <span className="cb_productWindow-cost">${props.product.value}/u</span>
            <Button onClick={props.openHandler(props.product)} >Comprar</Button>
        </div>
    )
}

export default ProductBlock;