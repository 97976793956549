export enum ProductCategory {
    Edibles,
    Misc
}

export interface IProduct {
    id: number;
    name: string;
    category: ProductCategory;
    value: number;
    img: string;
}

export interface ICartProduct extends IProduct {
    qty: number;
}
export interface ICart {
    items: Array<ICartProduct>;
}
