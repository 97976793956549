import { ProductCategory, IProduct } from "../../models/ICrazyBrowApi";

const Misc: Array<IProduct> = [{
    id: 3,
    name: 'Gorro',
    category: ProductCategory.Misc,
    value: 540,
    img: ''
},
{
    id: 4,
    name: 'Remera CrazyBrow',
    category: ProductCategory.Misc,
    value: 380,
    img: ''
}]

export default Misc;