import { createContext } from 'react';
import { Firebase } from '../Firebase';
import { RouterService } from '../services/RouterService';
import { Cart } from '../models/Cart';

export interface IUser {
    displayName: string;
    email: string;
}

export interface IAppContext {
    user: IUser;
    fireBase: Firebase;
    cart: Cart;
    router: RouterService;
}

export const FirebaseContext = createContext(null);

const CrazyBrowContext = createContext<IAppContext>(null);

export default CrazyBrowContext;