import * as React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import brownie from '../../img/brow1.png';
import { IProduct } from '../../models/ICrazyBrowApi';
import { Button } from 'react-bootstrap';
import CrazyBrowContext from '../../Context/globalContext';
import ProductQty from './ProductQty';
interface IProps {
    open: boolean;
    closeCallBack: () => void;
    product: IProduct;
}

const ProductWindow: React.FunctionComponent<IProps> = (props: IProps) => {
    if (props.open) {
        const context = React.useContext(CrazyBrowContext);
        const addToCart = () => context.cart.addToCart(props.product, productQty);
        const [productQty, setQty] = React.useState(0);
        return (
            <ReactCSSTransitionGroup
                transitionName="fade"
                transitionAppear={true}
                transitionLeave={true}
                transitionAppearTimeout={0}
                transitionEnterTimeout={0}
                transitionLeaveTimeout={0}
            >
                <div className="cb_productWindow">
                    <div className="cb_productWindow-draggableRow" /* onClick={dragContent} */>
                        <span className="cb_productWindow-closeButton" onClick={props.closeCallBack}>X</span>
                    </div>

                    <div className="cb_productWindow-product">
                        <img src={brownie} alt="" />
                        <span>{props.product.name}</span>
                        <span className="cb_productWindow-cost">${props.product.value}/u</span>
                        <ProductQty setProductQtyCallBack={setQty} qty={productQty} />
                        <Button onClick={addToCart}>Agregar al carrito</Button>
                    </div>
                </div>

            </ReactCSSTransitionGroup>
        )
    }
    return null;
}


/* const dragContent = (e: MouseEvent) => {
    const target = e.currentTarget;
e.
    if (target) {
        target. = null;
    }
} */

export default ProductWindow;