import React, { useState, useContext } from "react";
import CrazyBrowContext from "../../Context/globalContext";
import ProductWindow from "../../presentational/ProductWindow";
import { IProduct, ProductCategory } from "../../models/ICrazyBrowApi";
import ProductList from "../../presentational/ProductList";
import Products from '../../Products';
const Shop = () => {

    const [openProduct, setProduct] = useState<IProduct>(null);
    const [windowOpen, setProductWindow] = useState(false);
    const context = useContext(CrazyBrowContext);

    const openSpecificProduct = (product: IProduct) => () => {
        setProduct(product);
        setProductWindow(true);
    }

    return (
        <div className="mainDarkBG">
            <ProductList products={Products} openHandler={openSpecificProduct} />
            <ProductWindow product={openProduct} open={windowOpen} closeCallBack={() => setProductWindow(false)} />
        </div>
    );
}

export default Shop;