import * as React from 'react';

const Header: React.FunctionComponent = () => {
    return (
        <div className="cb_header">
            <video id="background-video" className="cb_header-video" loop autoPlay muted={true}>
                <source src={'./videobg.mp4'} type="video/mp4" />
                <source src={'./videobg.mp4'} type="video/ogg" />
                Your browser does not support the video tag.
            </video>
            <div className="shadowDiv"></div>
        </div>
    )
}

export default Header;