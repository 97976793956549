import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../../presentational/Header';
import Footer from '../../presentational/Footer'
import FirstSection from '../../presentational/FirstSection';
import SecondSection from '../../presentational/SecondSection';
import ContactForm from '../../presentational/Contact';
import DestailsSection from '../../presentational/DetailsSection';
import GoogleMaps from '../../presentational/Maps';
// imgs
class Landing extends Component<{}, {}> {

    render() {
        return (
            <>
                <Header />
                <FirstSection />
                <DestailsSection />
                <SecondSection />
                <ContactForm />
                <GoogleMaps />
                <Footer />
            </>
        );
    }

    /*   private closeProductWindow = () => {
        this.setState({ productWindowOpen: false })
      }
    
      private openProductWindow = () => {
        this.setState({ productWindowOpen: true })
      } */

}


export default Landing;
