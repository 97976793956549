import CrazyBrowContext from '../../Context/globalContext';
import Form from 'react-bootstrap/Form';
import { Button, Spinner } from 'react-bootstrap';
import * as React from 'react';

const RegisterForm = () => {
    const [formState, changeState] = React.useState({ email: '', password: '', name: '', isLoading: false });
    const context = React.useContext(CrazyBrowContext);
    const handleChange = (event) => {
        changeState({
            ...formState,
            [event.currentTarget.getAttribute('name')]: event.currentTarget.value
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (formState.email && formState.password) {
            changeState({ ...formState, isLoading: true });
            context.fireBase.createUserViaEmailAndPassword(formState.email, formState.password, formState.name)
                .then(() => {
                    console.log('registered successfully');
                })
                .catch(() => changeState({ ...formState, isLoading: false }));
        }
    }

    const formOrSpinner = !formState.isLoading ? (
        <div className="cb_form">
            <Form onSubmit={handleSubmit} className="grid">
                <Form.Group controlId="formBasicName">
                    <Form.Label>Nombre o apodo</Form.Label>
                    <Form.Control type="text" placeholder="Cómo te llamás?" onChange={handleChange} name="name" />
                    <Form.Text className="text-muted">
                        Así te vamos a llamar.
                </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Correo</Form.Label>
                    <Form.Control type="email" placeholder="Poné tu correo" onChange={handleChange} name="email" />
                    <Form.Text className="text-muted">
                        No lo vamos a compartir con nadie :)
                </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control type="password" placeholder="Elegí una contraseña" name="password" onChange={handleChange} />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Registrarse
                </Button>
            </Form>
        </div>
    ) : (<Spinner animation="grow" />);
    return (
        <div className="mainDarkBG">
            {formOrSpinner}
        </div>
    )
}

export default RegisterForm;