import * as React from 'react';
import logo from '../../img/logo.png';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CrazyBrowContext from '../../Context/globalContext';
import { LinkContainer } from "react-router-bootstrap";
import defaultUserImage from '../../img/defaultUserImage.jpg';
import './index.css';

const NavBar: React.FunctionComponent = () => {
    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="navbar" variant="dark" className="navBar-white" fixed="top">
                <Link to="/"><img className="App-logo" src={logo} alt="" /></Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <LinkContainer to="/"/* to="/tienda" */ className="navLinkCenter">
                            <Nav.Link>Tienda (pronto!)</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/#contacto" className="navLinkCenter">
                            <Nav.Link>Contacto</Nav.Link>
                        </LinkContainer>
                        {userOrLogInLink()}
                    </Nav>
                </Navbar.Collapse>
            </Navbar >
        </>);
}

const userOrLogInLink = () => {
    const context = React.useContext(CrazyBrowContext);

    return context.user !== null ? (
        <LinkContainer to="/usuario" >
            <Nav.Link className="userLink">
                <img src={defaultUserImage} alt="" />
                {context.user.displayName || 'usuario'}
            </Nav.Link>
        </LinkContainer>
    ) : (
            <LinkContainer to="/login" className="navLinkCenter">
                <Nav.Link>LogIn/Registro</Nav.Link>
            </LinkContainer>
        );
}

export default NavBar;
