import * as React from 'react';
import { checkPropTypes } from 'prop-types';

interface IProps {
    setProductQtyCallBack: (qty: number) => void;
    qty: number;
}
const ProductQty: React.FunctionComponent<IProps> = props => {
    const increaseQty = () => props.setProductQtyCallBack(props.qty + 1);
    const decreaseQty = () => props.setProductQtyCallBack(props.qty - 1);
    return (
        <div className="cb_productQty">
            <span onClick={decreaseQty} onMouseDown={decreaseQty}>-</span>

            <div>{props.qty}</div>
            <span onClick={increaseQty}>+</span>
        </div>
    );
}

export default ProductQty;