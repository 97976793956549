import * as React from "react";
import { instance as FirebaseInstance } from '../Firebase';

import { IUser } from "../Context/globalContext";
export const useAuth = () => {

    const [state, setState] = React.useState(() => {
        const user = FirebaseInstance.currentUser()
        return {
            initializing: !user,
            user: !user ? null : {
                email: user.email,
                displayName: user.displayName
            } as IUser,
        }
    })

    function onChange(user) {
        setState({ initializing: false, user })
    }

    React.useEffect(() => {
        // listen for auth state changes
        const unsubscribe = FirebaseInstance.auth.onAuthStateChanged(onChange)

        // unsubscribe to the listener when unmounting
        return () => unsubscribe()
    }, [])

    return state
}