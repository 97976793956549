import React, { Component } from 'react';
import logo from '../../img/logo.png';
import fbLogo from '../../img/fb.png';
const Footer: React.FunctionComponent = () => {
    return (
        <div className="cb_footer">
            <div className="cb_footer_data">
                <img className="App-logo" src={logo} alt="" />
                <span className="cb_vertical_divider">|</span>
                <a href="https://www.facebook.com/Crazybrow420/"><img src={fbLogo} alt="" /></a>
            </div>
        </div>
    );
}
export default Footer;
