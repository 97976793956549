import * as React from 'react';
import LoginForm from '../../presentational/LoginForm';
import CrazyBrowContext from '../../Context/globalContext';
import { Redirect } from 'react-router';

const LoginPage = props => {
    const context = React.useContext(CrazyBrowContext);
    const user = context.user;
    return user && user.displayName ? <Redirect to="/usuario" /> : <LoginForm />;
};

export default LoginPage;