import * as React from 'react';

const SecondSection: React.FunctionComponent = () => {
    return (
        <div className="cb_contentSection cb_secondContent">
            <h2 className="precauciones">Precauciones</h2>
            <p>
                <mark> Como precauciones necesitamos tener en cuenta el no consumo de más de una dosis (un brownie 🍩) si nunca antes había consumido. <br />
                Luego de que el consumo sea más moderado, se puede incrementar la dosis, siempre teniendo total conciencia de lo que se está realizando. <br />
                Como recomendación, por cualquier tipo de efecto o síntoma extraño recomendamos contactarse con su institución médica.</mark>
            </p>
            <p>
                MUTUALISTAS
            </p>
        </div>
    );
}

export default SecondSection;