import * as React from 'react';
import { Form, Button } from 'react-bootstrap';
import { ReplaceProps } from 'react-bootstrap/helpers';


enum Status {
    Default = 'default',
    Success = 'success',
    Error = 'error'
}
interface IState {
    name: string;
    phone: string;
    message: string;
    status: Status;
}

class ContactForm extends React.Component<{}, IState> {
    public componentWillMount() {
        this.setState({ phone: '', message: '', name: '', status: Status.Default })
    }
    render() {
        return (
            <div id="contacto" className="cb_contactForm">
                <h2>Contacto</h2>
                <Form className="cb_contactForm-form">
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control name="name" type="text" placeholder="Nombre" onChange={this.handleChange.bind(this)} />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Celular</Form.Label>
                        <Form.Control name="phone" type="number" placeholder="Dejá tu cel" onChange={this.handleChange.bind(this)} />
                        <Form.Text>
                            Cualquier cosa te contactamos por WhatsApp!
                    </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Mensaje</Form.Label>
                        <Form.Control name="message" as="textarea" value={this.state.message} rows="5" onChange={this.handleChange.bind(this)} />
                    </Form.Group>

                    <Button variant="primary" onClick={this.handleSubmit}>
                        Enviar
            </Button>
                    {this.message}
                </Form>
            </div>
        );
    }
    private get message() {
        switch (this.state.status) {
            case Status.Default:
            default:
                return null;
            case Status.Success:
                return 'Success';
            case Status.Error:
                return 'Error';
        }
    }
    private handleChange = (e: React.FormEvent<ReplaceProps<React.ElementType<any>, any>>) => {
        const name = e.currentTarget.name;
        const newState: IState = { ...this.state, [name]: e.currentTarget.value };
        this.setState(newState);
    }
    private handleSubmit = (e: React.FormEvent<ReplaceProps<React.ElementType<any>, any>>) => {
        if (this.state.phone && this.state.message) {
            fetch('http://crazybrow420.com/beta/sendEmail.php', {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phone: this.state.phone, message: this.state.message })
            }).then(res => {
                console.log(res);
                this.displaySuccessMessage()
            }).catch((e) => {
                console.log(e);
                this.displayErrorMessage();
            })
        }
    }

    private displayErrorMessage() {
        this.setState({ ...this.state, status: Status.Error })
    }

    private displaySuccessMessage() {
        this.setState({ name: '', phone: '', message: '', status: Status.Success })
    }
}

export default ContactForm;
