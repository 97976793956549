import * as React from 'react';
import CrazyBrowContext, { IUser } from './globalContext';
import { instance as FirebaseInstance } from '../Firebase';
import { instance as RouterInstance } from '../services/RouterService';
import { useAuth } from '../Hooks/useAuth';
import { Spinner } from 'react-bootstrap';
import { instance as CartInstance } from '../models/Cart';

const AppContext = (props: any) => {
    const { initializing, user } = useAuth()

    if (initializing) {
        return <div className="cb_blankState"><Spinner animation="border" /></div>
    }

    return (
        <CrazyBrowContext.Provider value={{
            user,
            cart: CartInstance,
            fireBase: FirebaseInstance,
            router: RouterInstance
        }}>
            {props.children}
        </CrazyBrowContext.Provider>
    );
}

// Only for dev purposes
Object.defineProperty(window, 'ctx', {
    value: {
        cart: CartInstance,
        fireBase: FirebaseInstance,
        router: RouterInstance
    }
})
export default AppContext;