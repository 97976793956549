import * as React from 'react';
import RegisterForm from '../../presentational/RegisterForm';
import LoginForm from '../../presentational/LoginForm';
import CrazyBrowContext from '../../Context/globalContext';
import { Redirect } from 'react-router';
import defaultUserImage from '../../img/defaultUserImage.jpg';
import UserMenu from './menu';

const UserPanel = () => {
    const context = React.useContext(CrazyBrowContext);
    if (context.user) {
        return (
            <div className="cb_userPanel">
                <div className="cb_userImage">
                    <img src={defaultUserImage} alt="" />
                </div>
                <div>
                    <span>Hola, {context.user.displayName}</span>
                    <UserMenu />
                </div>
            </div>
        )
    } else {
        return <Redirect to="/login" />
    }
}

export default UserPanel;