import CrazyBrowContext from '../../Context/globalContext';
import Form from 'react-bootstrap/Form';
import { Button, Spinner } from 'react-bootstrap';
import * as React from 'react';
import LinkContainerButton from '../Buttons/LinkContainerButton';
import { AppRoutes } from '../../services/Routes';
import { auth } from 'firebase';


const LoginForm = props => {
    const [formState, changeState] = React.useState({ email: '', password: '', isLoading: false, errorMessage: null });
    const context = React.useContext(CrazyBrowContext);
    const handleChange = (event) => {
        changeState({
            ...formState,
            [event.currentTarget.getAttribute('name')]: event.currentTarget.value
        });

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (formState.email && formState.password) {
            changeState({ ...formState, isLoading: true });
            context.fireBase.signInViaEmailAndPassword(formState.email, formState.password)
                .then(user => {
                    console.log('resolved inside loginform')
                    changeState({ ...formState, isLoading: false });
                    context.router.goToUrl('/usuario');
                }).catch((e: auth.Error) => {
                    changeState({ ...formState, isLoading: false, errorMessage: e.message });
                });
        }
    }

    const formOrSpinner = !formState.isLoading ? (
        <div className="cb_form">
            <Form onSubmit={handleSubmit} className="grid">
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Correo</Form.Label>
                    <Form.Control type="email" placeholder="Poné tu correo" onChange={handleChange} name="email" />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control type="password" placeholder="Elegí una contraseña" name="password" onChange={handleChange} />
                </Form.Group>
                <div className="errors">{formState.errorMessage ? formState.errorMessage : null}</div>
                <div className="loginMenuActions">
                    <Button variant="primary" type="submit">
                        Entrar
            </Button>
                    <LinkContainerButton path={AppRoutes.register} />
                </div>
            </Form>
        </div>
    ) : (<Spinner animation="grow" />);
    return (
        <div className="mainDarkBG">
            {formOrSpinner}
        </div>
    )
}

export default LoginForm;