import { ProductCategory, IProduct } from "../../models/ICrazyBrowApi";

const Edibles: Array<IProduct> = [{
    id: 1,
    name: 'Brownie',
    category: ProductCategory.Edibles,
    value: 240,
    img: ''
},
{
    id: 2,
    name: 'Galleta',
    category: ProductCategory.Edibles,
    value: 240,
    img: ''
}]

export default Edibles;