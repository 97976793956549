import * as React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, ButtonProps } from 'react-bootstrap';
import { AppRoutes } from '../../services/Routes';

interface IProps extends ButtonProps {
    path: AppRoutes;
}
const LinkContainerButton = (props: IProps) => {
    const { path, ...buttonProps } = props;
    return (
        <LinkContainer to={path}>
            <Button {...buttonProps}>Crear cuenta</Button>
        </LinkContainer>
    )
}

export default LinkContainerButton;