import { ICart, IProduct, ICartProduct } from "./ICrazyBrowApi";

export class Cart implements ICart {
    public items: Array<ICartProduct> = [];

    public addToCart(item: IProduct, qty: number) {
        const itemExists = this.items.find(i => i.id === item.id);

        if (itemExists) {
            itemExists.qty += qty;
        } else {
            this.items.push({ ...item, qty });
        }
        console.log(this.items);
    }

    public removeFromCart(item: IProduct) {
        this.items = this.items.filter(i => i.id !== item.id);
    }

    public increaseQty(item: IProduct) {
        this.items.find(i => i.id === item.id).qty++;
    }

    public decreaseQty(item: IProduct) {
        this.items.find(i => i.id === item.id).qty--;
    }

    public changeQty(item: IProduct, newQty: number) {
        const itemToChange = this.items.find(i => i.id === item.id);
        itemToChange.qty = newQty;
    }

    public get totalValue() {
        return this.items.reduce((prev, curr) => prev + (curr.value * curr.qty), 0);
    }
}

export const instance = new Cart();