import { createBrowserHistory } from 'history';


export class RouterService {
    private history: History;
    constructor() {
        this.history = window.history;
    }

    public goToUrl = (url: string) => this.history.pushState(null, 'asd', url);

    public currentLocation = () => `/${location.href.match(/([^\/]*)\/*$/)[1]}`;
}

export const instance = new RouterService()